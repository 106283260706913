
.chakra-portal > * {
    z-index: 100 !important;
 }

:root {
    --link-color: #7031EB;
    --hover-opacity: .7;
}

#popover-content-wrapper a {
    color: var(--link-color);
}

#popover-content-wrapper a:hover {
    opacity: var(--hover-opacity);
}

#popover-content-wrapper a:focus,
#popover-content-wrapper a:focus-visible {
    border: none !important;
    outline: none !important;
    outline-offset: unset !important;
}





